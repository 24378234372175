<template>
  <v-responsive
    class="image-content v-img"
    :aspect-ratio="content.aspectRatio"
    :aria-label="content.alt"
    :role="content.alt ? 'img' : undefined"
    :style="{
      height,
      width,
    }"
  >
    <NuxtImg
      class="v-img__img image-content__img"
      :class="{
        'v-img__img--cover': fit === 'cover',
        'v-img__img--contain': fit === 'contain',
      }"
      v-bind="nuxtImgProps"
      :src="src"
    />
    <OverlayBlock
      v-if="content.overlay || content.link"
      :content="{ ...content.overlay, link: content.link }"
    />
  </v-responsive>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { omit } from "lodash";
import type { ImageContent } from ".";
import { useResizable } from "./utils";

export default defineComponent({
  name: "ImageBlock",
  props: {
    content: { type: Object as PropType<ImageContent>, required: true },
  },
  setup(props) {
    const src = useResizable(props.content.src);

    return {
      src,
    };
  },
  computed: {
    nuxtImgProps() {
      return {
        ...omit(this.content, [
          "aspectRatio",
          "link",
          "loading",
          "overlay",
          "fit",
          "src",
        ]),
        fit: this.fit,
      };
    },
    width() {
      return convertToUnit(this.content.width);
    },
    height() {
      return convertToUnit(this.content.height || "100%");
    },
    fit() {
      return this.content.fit || "cover";
    },
    link() {
      return resolveLink(this.content.link);
    },
  },
});
</script>

<style lang="scss" scoped>
.image-content {
  img {
    border-style: none;
  }
}
</style>
